// extracted by mini-css-extract-plugin
export var container = "layout-module--container--eLBMS";
export var headingContainer = "layout-module--heading-container--ezb4G";
export var headingTagline = "layout-module--heading-tagline--5N5Mf";
export var heading = "layout-module--heading--8VjLO";
export var headingImage = "layout-module--heading-image--4HnCR";
export var headingLink = "layout-module--heading-link--GV0R0";
export var main = "layout-module--main--5VKR9";
export var siteTitle = "layout-module--site-title--5N6mf";
export var mainNav = "layout-module--main-nav--xOGuV";
export var navLinks = "layout-module--nav-links--EROwB";
export var navLinkItem = "layout-module--nav-link-item--pfCo2";
export var navLinkText = "layout-module--nav-link-text--ac2nV";
export var navLinkTextActive = "layout-module--nav-link-text-active--Vi5nz";
export var video = "layout-module--video--qE80G";
export var videoContent = "layout-module--video-content--FYuV5";
export var post = "layout-module--post--WJJr3";
export var postFocus = "layout-module--post-focus--9Z+4h";
export var postHeader = "layout-module--post-header--CAqmD";
export var postTitle = "layout-module--post-title--NrVGv";
export var postTags = "layout-module--post-tags--Y9yWN";
export var postTagContainer = "layout-module--post-tag-container--TSPht";
export var tagContent = "layout-module--tag-content--DJB7r";